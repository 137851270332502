import React from "react";
import { ReactComponent as HeartIcon } from "assets/img/heart.svg";
import packageInfo from "../../../package.json";

const Footer = () => {
	const version = packageInfo.version;

	return (
		<footer className="my-5 mx-sm-9 mx-3 text-light d-flex flex-column">
			<div className="row align-items-center">
				<div className="col-md">
					If you found this tool useful, remember builders need some
					coffee:
					<br />
					erd1g3dygd6kh2nt5kvkl6fpp3rp3y9ez5wfaxwwfzzkglwqn2aydn3q6vqkxk
				</div>

				<div className="ms-auto text-end d-inline col-md mt-3">
					<a
						{...{
							target: "_blank",
						}}
						className="text-white text-decoration-none text-decoration-hover-underlined"
						href="https://tortuga-staking.com/"
					>
						Built by DB Coding SRL &amp; Tortuga Staking.
					</a>
					&nbsp;
					<a
						{...{
							target: "_blank",
						}}
						className="text-white text-decoration-none text-decoration-hover-underlined"
						href="https://multiversx-ui.com/?ref=xbulk"
					>
						Made with MultiversX UI
					</a>
					&nbsp;
					<a
						{...{
							target: "_blank",
						}}
						className="text-white text-decoration-none text-decoration-hover-underlined"
						href="https://nf-tim.creative-tim.com/?ref=xbulk"
					>
						by NF-Tim.
					</a>
					<p>Version {version}</p>
				</div>
			</div>
			<div className="text-center mx-auto">
				<a
					className="d-flex align-items-center mx-auto text-white text-decoration-none text-decoration-hover-underlined"
					href="https://github.com/defralcoding/xBulk"
				>
					Made with <HeartIcon className="mx-1" /> for community
				</a>
			</div>
		</footer>
	);
};

export default Footer;
