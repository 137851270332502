import { computeShard } from "MyApiNetworkProvider";
import {
	Address,
	SmartContract,
	AbiRegistry,
	ResultsParser,
	VariadicValue,
	Struct,
} from "@multiversx/sdk-core";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import xBulkFactoryAbi from "xbulkfactory.abi.json";

const domain = window.location.hostname;

const domainMainnet = "xbulk.app";
const domainDevnet = "devnet.xbulk.app";
const domainTestnet = "testnet.xbulk.app";

const addressMainnet =
	"erd1qqqqqqqqqqqqqpgq5j3wahajwehwja70v39074zzzjsq89lkdn3qp3j2f9";
const addressDevnet =
	"erd1qqqqqqqqqqqqqpgq5j3wahajwehwja70v39074zzzjsq89lkdn3qp3j2f9";
const addressTestnet =
	"erd1qqqqqqqqqqqqqpgq5j3wahajwehwja70v39074zzzjsq89lkdn3qp3j2f9";

export const burnAddress =
	"erd1deaddeaddeaddeaddeaddeaddeaddeaddeaddeaddeaddeaddeaqtv0gag";

export const environment: string =
	domain === domainMainnet
		? "mainnet"
		: domain === domainTestnet
		? "testnet"
		: "devnet";

export const dAppName = "xBulk";

export const walletConnectV2ProjectId = "895dfd3a05b87c0dd4c0ab9794107fe5";
export const metamaskSnapWalletAddress = "https://snap-wallet.multiversx.com";

export async function getContracts(
	userAddress: string,
	proxyAddress: string
): Promise<string[]> {
	const shard = computeShard(new Address(userAddress));
	var contractsToReturn = [];

	if (userAddress) {
		const xBulkFactoryContract = new SmartContract({
			address: new Address(getFactoryContract()),
			abi: AbiRegistry.create(xBulkFactoryAbi),
		});

		const interaction = xBulkFactoryContract.methods.getUserContracts([
			userAddress,
		]);
		const query = interaction.check().buildQuery();
		const networkProvider = new ProxyNetworkProvider(proxyAddress);
		const queryResponse = await networkProvider.queryContract(query);
		const typedBundle = new ResultsParser().parseQueryResponse(
			queryResponse,
			interaction.getEndpoint()
		);
		if ((typedBundle.firstValue as VariadicValue).valueOf().length) {
			(typedBundle.firstValue as VariadicValue)
				.valueOf()
				.forEach((item) => {
					const address = item.address.bech32();
					const name = Buffer.from(item.name, "base64").toString(
						"utf-8"
					);

					contractsToReturn.push({
						address,
						public: false,
						name,
					});
				});
		}
	}

	if (environment === "testnet") {
		contractsToReturn.push({
			address: addressTestnet,
			public: true,
			name: "Distribution Contract",
		});
	}

	if (environment === "devnet") {
		contractsToReturn.push({
			address: addressDevnet,
			public: true,
			name: "Distribution Contract",
		});
	}

	if (environment === "mainnet") {
		contractsToReturn.push({
			address:
				shard === 0
					? "erd1qqqqqqqqqqqqqpgqtnksqd6rs4a74mf4un5h2w2tt5lanfmaayxqs35jth"
					: shard === 1
					? "erd1qqqqqqqqqqqqqpgqwcv369k9x49ve3qlu0h5qe949w7m6gcxh42scqtdpf"
					: "erd1qqqqqqqqqqqqqpgq5j3wahajwehwja70v39074zzzjsq89lkdn3qp3j2f9",
			public: true,
			name: "Distribution Contract",
		});
	}

	return contractsToReturn;
}

export function getFactoryContract() {
	if (environment === "mainnet") {
		return "erd1qqqqqqqqqqqqqpgqnv4ztj3jvnztruk394z76f99tgwqtusldn3qqh9hpm";
	} else if (environment === "testnet") {
		return "erd1qqqqqqqqqqqqqpgqmw8jxe3pkqmutgag68s2zmlgtls5ren0dn3qk369vx";
	} else if (environment === "devnet") {
		return "erd1qqqqqqqqqqqqqpgq9r8xzzulfap7xclqkl3mpsgcm03vup4pdn3qk8wsm7";
	}
}

export const detectCurrentPlatform = () => {
	if ((window as any)?.ReactNativeWebView) {
		return "reactNative";
	}
	if ((window as any).webkit) {
		return "ios";
	}
	return "web";
};
