import React from "react";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";
import { logout } from "@multiversx/sdk-dapp/utils";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dAppName, detectCurrentPlatform } from "config";
import { routeNames } from "routes";
import { environment } from "config";
import { ReactComponent as MultiversxLogo } from "assets/img/multiversx.svg";
import { shortenAddress } from "utils/functions";

const Navbar = () => {
	const { address } = useGetAccountInfo();
	const platform = detectCurrentPlatform();

	const handleLogout = () => {
		logout(`${window.location.origin}/`);
	};

	const isLoggedIn = Boolean(address);

	return (
		<BsNavbar className="mx-bg rounded-2 px-3 py-3 mx-sm-9 my-sm-5 mx-3 my-3">
			<div className="container-fluid">
				<Link
					className="d-flex align-items-center navbar-brand mr-0"
					to={routeNames.home}
				>
					<MultiversxLogo className="multiversx-logo me-1" />
					<span className="dapp-name text-white ps-2">{dAppName}</span>
				</Link>

				<Nav className="ml-auto">
					{isLoggedIn ? (
						<span className="text-white d-flex align-items-center me-3 wallet hide-small">
							{shortenAddress(address)}
						</span>
					) : null}

					{environment !== "mainnet" ? (
						<span className="text-white mx-2 d-flex ms-0 me-3 align-items-center">
							{environment.toUpperCase()}
						</span>
					) : null}

					{isLoggedIn && platform !== "reactNative" && (
						<NavItem>
							<button
								className="btn btn-primary py-2 px-4"
								onClick={handleLogout}
							>
								Logout
							</button>
						</NavItem>
					)}
				</Nav>
			</div>
		</BsNavbar>
	);
};

export default Navbar;
