import * as React from "react";
import { useState, useEffect } from "react";
import { request } from "graphql-request";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { ExplorerLink } from "@multiversx/sdk-dapp/UI";
import { Link } from "react-router-dom";
import { routeNames } from "routes";
import { dAppName, environment, getContracts } from "config";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import CountUp from "react-countup";
import { InitialBackground } from "components/InitialBackground";
import { ReactComponent as MultiversxLogo } from "assets/img/multiversx.svg";

const Home = () => {
	const account = useGetAccountInfo();
	const isLoggedIn = Boolean(account.address);
	const [contracts, setContracts] = useState([]);
	const privateContracts = contracts.filter((c) => !c.public);

	const {
		network: { apiAddress, explorerAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transferCount, setTransferCount] = useState(0);

	useEffect(() => {
		if (contracts?.length > 0) {
			getTransferCount();
		}
	}, [contracts]);

	useEffect(() => {
		getContracts(account.address, apiAddress).then((contracts) =>
			setContracts(contracts)
		);
	}, [account.address, apiAddress]);

	const getTransferCount = async () => {
		request(
			"https://dapps-backend.dbcodingsrl.com/graphql",
			`
            query {
                xBulk {
                    totalOperations
                }
            }
            `
		).then(({ xBulk }) => {
			setTransferCount(xBulk.totalOperations);
		});
	};

	return (
		<>
			<InitialBackground />
			<div className="d-flex align-items-center container w-100">
				<div className="col-12 col-md-8 col-lg-5 mx-auto">
					<div className="text-center">
						<div className="fs-1 fw-bold mb-3">
							<span className="">{dAppName}</span>
							<span className="color-primary"> App</span>
						</div>
						<p className="mb-6 text-light">
							The best collection of tools for making your life
							easier with lots of transactions.
						</p>
					</div>

					<div className="card card-glowing shadow-sm rounded p-4 border-0">
						<div className="card-body text-center">
							{transferCount !== 0 ? (
								<div className="display-4 justify-content-center align-items-center mb-4">
									<div className="color-primary fw-bold">
										<CountUp
											start={0}
											end={Math.trunc(
												transferCount / 1000
											)}
											duration={1}
											useEasing={true}
										/>
										k +
									</div>
									<h5 className="text-left text-light mt-1">
										Transactions Processed with xBulk
									</h5>
								</div>
							) : null}

							{isLoggedIn ? (
								<>
									<div>
										<p className="mb-3">
											<br /> Start preparing your
											transactions.
										</p>

										<Link
											to={routeNames.bulkTransaction}
											className="btn btn-primary py-2 px-4 mx-2 mt-3"
										>
											Bulk Transactions
										</Link>

										<Link
											to={routeNames.nftDistribution}
											className="btn btn-primary py-2 px-4 mx-2 mt-3"
										>
											NFTs Distribution
										</Link>

										<Link
											to={routeNames.lottery}
											className="btn btn-primary py-2 px-4 mx-2 mt-3"
										>
											Lottery
										</Link>

										<Link
											to={routeNames.nftMultiSend}
											className="btn btn-primary py-2 px-4 mx-2 mt-3"
										>
											NFT Multi Send
										</Link>
									</div>

									<div className="hr" />

									{privateContracts.length === 0 ? (
										<>
											<p
												className="m-4 text-light"
												style={{ fontWeight: 400 }}
											>
												You can create your first
												private distribution contract
											</p>
										</>
									) : (
										<div className="my-4">
											<p
												className="text-light"
												style={{ fontWeight: 400 }}
											>
												You have
												<span className="text-primary">
													&nbsp;
													{privateContracts.length}
													&nbsp;
												</span>
												private contract
												{privateContracts.length > 1 &&
													"s"}
											</p>
											{privateContracts.map(
												(contract) => (
													<p>
														<ExplorerLink
															page={
																explorerAddress +
																"/accounts/" +
																contract.address
															}
														>
															{contract.name}
														</ExplorerLink>
													</p>
												)
											)}
										</div>
									)}

									<Link
										to={routeNames.contractCreator}
										className="btn btn-primary py-2 px-4 mx-2"
									>
										Create Contract
									</Link>
								</>
							) : (
								<>
									<div className="d-flex justify-content-center align-items-center">
										<span>Login using your </span>
										<MultiversxLogo
											width={100}
											style={{
												display: "inline-block",
												verticalAlign: "middle",
												marginRight: 10,
												marginLeft: 10,
											}}
										/>{" "}
										<span>Wallet to start.</span>
									</div>

									<Link
										to={routeNames.unlock}
										className="btn btn-primary py-2 px-4 mt-3"
									>
										Login
									</Link>
								</>
							)}

							<div className="hr" />

							<p
								className="m-4 text-light"
								style={{ fontWeight: 400 }}
							>
								You are now on {environment}.<br />
								You can also use the tool on:
							</p>
							{environment !== "mainnet" ? (
								<a
									href={`https://xbulk.app`}
									className="btn btn-secondary py-2 px-4 mx-2"
								>
									Mainnet
								</a>
							) : null}
							{environment !== "testnet" ? (
								<a
									href={`https://testnet.xbulk.app`}
									className="btn btn-secondary py-2 px-4 mx-2"
								>
									Testnet
								</a>
							) : null}
							{environment !== "devnet" ? (
								<a
									href={`https://devnet.xbulk.app`}
									className="btn btn-secondary py-2 px-4 mx-2"
								>
									Devnet
								</a>
							) : null}
						</div>

						<div className="hr mb-2" />

						<a
							href="https://linktr.ee/xObserver"
							className="btn btn-secondary py-2 px-4 mx-2"
						>
							Our new Mobile Explorer is out! Download it!
							<img
								src="/xObserver.jpg"
								className="img-fluid"
								alt="xObserver is out"
							/>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
