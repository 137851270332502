import React from "react";

const PageHeader = ({ title, subtitle }) => {
	return (
		<div className="text-center w-75 mx-auto mt-1 mb-6">
			<div className="fs-1 fw-bold mb-3">{title}</div>
			<p className="text-light">{subtitle}</p>
		</div>
	);
};

export default PageHeader;
