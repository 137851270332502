import React from "react";
import { BallSpinner } from "react-spinners-kit";

const Step3 = ({ nfts, prevStep, transactionStatus }) => {
	const nftsToSend = nfts.filter((nft) => nft.toSend);

	return (
		<>
			{transactionStatus.isPending && (
				<>
					<BallSpinner color="#23f7dd" />
					<p className="fs-2 mt-3 fw-bold text-primary">
						NFT transfer initiated! Your NFT is en route. Sit back and relax!
					</p>
				</>
			)}

			{transactionStatus.isSuccessful && (
				<>
					<p className="fs-4 mb-1">NFTs Sent</p>
					<p className="fs-2 fw-bold text-primary">{nftsToSend.length}</p>

					<p className="fs-5 text-light mt-6 mb-2">
						If you found this tool useful, remember builders need some coffee:
					</p>
					<p className="text-light">
						erd1g3dygd6kh2nt5kvkl6fpp3rp3y9ez5wfaxwwfzzkglwqn2aydn3q6vqkxk
					</p>
				</>
			)}

			{transactionStatus.isFailed && (
				<>
					<p className="fs-5 mt-6 mb-2 text-primary">
						Oops... Looks like something didn't quite work its magic. Here is
						what the blockchain says: {transactionStatus.errorMessage}
					</p>
					<p className="fs-5">
						Please check your transaction and try again. If the issue persists,
						don't hesitate to contact us for further assistance. Our team is
						here to help unravel the mystery and get your transactions back on
						the enchanted path!
					</p>
				</>
			)}

			<div className="text-center mt-5">
				<button className="btn btn-secondary py-2 px-4" onClick={prevStep}>
					Back
				</button>
			</div>
		</>
	);
};

export default Step3;
