import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import { shortenAddress } from "utils/functions";
import { Address, TokenPayment } from "@multiversx/sdk-core";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";
import { useParams } from "react-router-dom";

const LotteryResult = () => {
	const txHash = useParams<{ txHash: string }>().txHash;

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transaction, setTransaction] = useState(null);

	useEffect(() => {
		fetch(apiAddress + "/transactions/" + txHash)
			.then((response) => response.json())
			.then((data) => {
				data.action.arguments.transfers.forEach((transfer) => {
					const tokenPayment = new TokenPayment(
						transfer.collection ?? transfer.token,
						parseInt(
							transfer.identifier?.split?.("-")[2] ?? "0",
							16
						),
						transfer.value,
						transfer.decimals ?? 0
					);
					transfer.tokenPayment = tokenPayment;
				});

				setTransaction(data);
			});
	}, []);

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						<span className="text-primary">Lottery Result</span>
					</>
				}
				subtitle="Blockchain makes lotteries super clear and simple! See the lottery outcome here"
			/>
			<PageBody>
				{transaction === null ? (
					"Loading..."
				) : (
					<div className="text-center">
						<h2 className="fw-bold mb-4">
							Transaction Hash:&nbsp;
							<span className="text-primary">
								{shortenAddress(transaction.txHash)}
							</span>
						</h2>

						<h2 className="fw-bold mb-4">
							Number of participants:&nbsp;
							<span className="text-primary">
								{
									transaction.action.arguments.functionArgs
										.length
								}
							</span>
						</h2>

						<hr />

						<h1 className="fw-bold mb-4 text-primary">Prizes</h1>
						{transaction.action.arguments.transfers.map(
							(transfer, i) => (
								<div className="text-center">
									<h2 className="fw-bold mb-4">
										{i + 1} winner prize:&nbsp;
										<span className="text-primary">
											{transfer.tokenPayment.nonce !==
												0 &&
											transfer.tokenPayment.amountAsBigInteger.eq(
												1
											) ? (
												transfer.tokenPayment
													.tokenIdentifier +
												" #" +
												transfer.tokenPayment.nonce
											) : (
												<FormatAmount
													value={
														transfer.tokenPayment
													}
													token={
														transfer.tokenPayment
															.tokenIdentifier
													}
													decimals={
														transfer.tokenPayment
															.numDecimals
													}
													showLastNonZeroDecimal={
														true
													}
													digits={0}
												/>
											)}
										</span>
									</h2>
								</div>
							)
						)}

						<hr />

						<h1 className="fw-bold mb-4 text-primary">
							Participants
						</h1>
						<table className="table table-mvx">
							<thead>
								<tr>
									<th scope="col">#</th>
									<th scope="col">ADDRESS</th>
								</tr>
							</thead>

							<tbody>
								{transaction.action.arguments.functionArgs.map(
									(addressHex, i) => (
										<tr key={i}>
											<th scope="row">{i + 1}.</th>
											<td>
												{Address.fromHex(
													addressHex
												).toString()}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default LotteryResult;
