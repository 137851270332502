import React from "react";

const NftViewer = ({ nft, callback }) => {
	const identifier = nft.identifier;
	const name = nft.name;
	const imageUrl = nft.media[0]?.thumbnailUrl;

	const checked = nft.toSend ?? false;

	return (
		<label className="form-check-label" htmlFor={"check-" + identifier}>
			<div className="card card-nft mb-4">
				<div className="card-header p-0 mx-3 mt-3 position-relative z-index-1">
					<div className="hover-img border-radius-lg">
						<img
							src={imageUrl}
							className="img-fluid border-radius-lg"
							alt={identifier}
						/>
					</div>
				</div>
				<div className="card-body py-3 d-flex justify-content-between">
					<h6 className="m-0 font-weight-bold">{name}</h6>
					<h6 className="m-0 font-weight-bold text-end">{identifier}</h6>
				</div>
				<input
					type="checkbox"
					className="form-control form-check-input m-2"
					id={"check-" + identifier}
					checked={checked}
					onChange={(e) => callback(e)}
				/>
			</div>
		</label>
	);
};

export default NftViewer;
