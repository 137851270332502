const bannedAddresses = [
	"erd1dwjphzfjxs72xhnwuj5j3yf9n7h0t6suvyyeqz99xl4c85l802jsvprpsx",
	"erd1ccqtn900quq3zvfnzxw52x0avrxa9gn26w7rm0xm5u86pxdaq62qq6pxaf",
	"erd1pv6n6mjhzkwazpzdz4zptrr944qtrrujc4cx5j2dln4xzm7cwj4ssg3g72",
	"erd1v35wx024r824qjh6fqrn9an6ewf5x4wjzvctcl05jhsy9mhcg23qw88qzp",
	"erd1v2f4d4t7ljaq2rg3t9qs9guwahtm6qmvzj2a2yjp3zs24jxvu8sqax56n0",
	"erd1r4f8vvcwu72c0ug05974v6j9pm7ammwydf9xwn7w06vzn5h6njnqjxp6x4",
	"erd16xy3exuauy5mnfewxtguly9fyv5j53jx9suastc2vl0x5dmp397stjrptd",
	"erd1zuxdeskpcy2lt323w4qzgg2trlj5wzv0rslphyq870wc9fs8lazqs62rm4",
	"erd1qqqqqqqqqqqqqpgqwcv369k9x49ve3qlu0h5qe949w7m6gcxh42scqtdpf",
	"erd1xvtyu3ltydr6epetgdxmqhgr8eq5yavp75cy2qednzhmr9xnshfqg5fm4u",
	"erd1y6vjn87dzmwvcc5875cw823sq6rhlk6g4gh2ppl8wuk928xp322s93f53r",
	"erd1cs693p5y6zfdvqg3qtz56j3hssrhptn799k4gjn3p46y97pw6t0s023wc7",
	"erd1th9plquffv6rs3wp6u837lr5uf2cdjeahgklupkley7x0ut368hquk7a93",
	"erd147q0yqpkal24zrdl5lhz569vudf4rj3j5g0npcql64hq8l9lkztsss97hu",
	"erd1hpke0zw8qpevrpuxx2l2rv882j2la5cdxj9qwdps2jlwcsmwd46qfkcwqg",
	"erd19l7nqyy2vmmmsep99yjujutwnj444ctpy3vpxmvydw45yu8xdeys2lhz24",
	"erd1jaetvkmwcvxq43lk0ytnesnj5d7jcwcrknz6u606k7es0mld0pfsuncq58",
	"erd10qr492zg0z4u6v3prm2my4689rd34q8e8sjcwmxqq6ms4s058f5sma2wt9",
	"erd1a6n35rs0xky0md3lwgj67d04c8emguc0xclktww8ql4cug0wx4tqlqsl5p",
	"erd1z8cjgew0xrzstrdnm9psmcgwytat0rqqj3tmy85uspqgfpqa88pqmj08dr",
	"erd158gr7mrqqh3vrylq0pwy05jzfk4ng82ke3hlff6v0gpz2t2phg0s3cypan",
	"erd1dkm59wrmv4763ke04pz0sfu4rwuywn3n3ru699nrw9hg0mwsxvvqy20mgg",
	"erd1m8es7jvxvmqjh0efuwnt4qfrggzrkasmw3m43upmqsfkxrgru5eqtd84tr",
	"erd1aqrwcvxdegrhvka0yuqdgdkh55nf92t28wns82n664khgu7fykusfvn69p",
];

export default bannedAddresses;
