import { refreshAccount } from "@multiversx/sdk-dapp/utils";
import { sendTransactions as mvxSendTransaction } from "@multiversx/sdk-dapp/services";

export const shortenAddress = (address, length = 8) => {
	return (
		address.substring(0, length) +
		"..." +
		address.substring(address.length - length, address.length)
	);
};

export const sendTransactions = async (
	transactions,
	transactionsDisplayInfo
) => {
	await refreshAccount();

	const { sessionId, error } = await mvxSendTransaction({
		transactions,
		transactionsDisplayInfo,
	});

	return { sessionId, error };
};

export const string2hex = (tmp) => {
	var str = "";
	for (var i = 0; i < tmp.length; i++) {
		str += tmp[i].charCodeAt(0).toString(16);
	}
	return str;
};
