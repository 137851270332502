import React from "react";
import {
	TransactionsToastList,
	SignTransactionsModals,
	NotificationModal,
} from "@multiversx/sdk-dapp/UI";
import { DappProvider } from "@multiversx/sdk-dapp/wrappers";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Layout from "components/Layout";
import PageNotFound from "pages/PageNotFound";
import routes from "routes";
import {
	environment,
	metamaskSnapWalletAddress,
	walletConnectV2ProjectId,
} from "config";

const App = () => {
	return (
		<Router>
			<DappProvider
				environment={environment}
				customNetworkConfig={{
					name: "customConfig",
					apiTimeout: 8000,
					walletConnectV2ProjectId,
					...(environment === "mainnet"
						? {
								metamaskSnapWalletAddress:
									"https://snap-wallet.multiversx.com",
						  }
						: environment === "testnet"
						? {
								metamaskSnapWalletAddress:
									"https://testnet-snap-wallet.multiversx.com",
						  }
						: {
								metamaskSnapWalletAddress:
									"https://devnet-snap-wallet.multiversx.com",
						  }),
				}}
				dappConfig={{
					shouldUseWebViewProvider: true,
				}}
			>
				<Layout>
					<TransactionsToastList />
					<NotificationModal />
					<SignTransactionsModals className="custom-class-for-modals" />
					<Routes>
						{routes.map((route: any, index: number) => (
							<Route
								path={route.path}
								key={"route-key-" + index}
								element={<route.component />}
							/>
						))}
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Layout>
			</DappProvider>
		</Router>
	);
};

export default App;
