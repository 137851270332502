import React from "react";
import { BallSpinner } from "react-spinners-kit";

const Step3 = ({ participantsToSend, prevStep, transactionStatus }) => {
	const transaction = transactionStatus.transactions[0];

	return (
		<>
			{transactionStatus.isPending && (
				<>
					<BallSpinner color="#23f7dd" />
					<p className="fs-2 mt-3 fw-bold text-primary">
						The draw is in progress, and the winner is about to
						emerge from the cosmic abyss. Sit tight!
					</p>
				</>
			)}

			{transactionStatus.isSuccessful && (
				<>
					<p className="fs-4 mb-1">Lottery Participants</p>
					<p className="fs-2 fw-bold text-primary">
						{participantsToSend.length}
					</p>

					{transaction && (
						<p>
							Feel free to share the link below with your
							community. This link offers an in-depth view of the
							draw's fairness and transparency on the blockchain,
							displaying all participants and prizes distributed:
							<br />
							<a
								href={`${window.location.hostname}/lotteryResult/${transaction.hash}`}
								target="_blank"
								rel="noreferrer"
								className="text-primary"
							>
								{window.location.hostname}
								/lotteryResult/
								{transaction.hash}
							</a>
						</p>
					)}

					<p className="fs-5 text-light mt-6 mb-2">
						If you found this tool useful, remember builders need
						some coffee:
					</p>
					<p className="text-light">
						erd1g3dygd6kh2nt5kvkl6fpp3rp3y9ez5wfaxwwfzzkglwqn2aydn3q6vqkxk
					</p>
				</>
			)}

			{transactionStatus.isFailed && (
				<>
					<p className="fs-5 mt-6 mb-2 text-primary">
						Oops... Looks like something didn't quite work its
						magic. Here is what the blockchain says:{" "}
						{transactionStatus.errorMessage}
					</p>
					<p className="fs-5">
						Please check your transaction and try again. If the
						issue persists, don't hesitate to contact us for further
						assistance. Our team is here to help unravel the mystery
						and get your transactions back on the enchanted path!
					</p>
				</>
			)}

			<div className="text-center mt-5">
				<button
					className="btn btn-secondary py-2 px-4"
					onClick={prevStep}
				>
					Back
				</button>
			</div>
		</>
	);
};

export default Step3;
