import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";
import { Step1, Step2, Step3 } from "components/pages/nftMultiSend";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";

const NftMultiSend = () => {
	const steps = [
		"Choose the NFTs you want to send",
		"Check the Transaction Summary",
		"Done!",
	];
	const [step, setStep] = useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transactionId, setTransactionId] = useState(null);
	const transactionStatus = useTrackTransactionStatus({ transactionId });

	const account = useGetAccountInfo();
	const [nfts, setNfts] = useState([]);

	const [userInput, setUserInput] = useState({
		receiver: "",
		receiverObj: null,
	});

	useEffect(() => {
		(async () => {
			setNfts(await apiNetworkProvider.getAccountAllNfts(account.address));
		})();
	}, []);

	useEffect(() => {
		if (transactionStatus.isPending) {
			nextStep();
		}
	}, [transactionStatus.isPending]);

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						Transfer <span className="text-primary">lots of NFTs</span>
						&nbsp;to a single wallet in a matter of seconds
					</>
				}
				subtitle="Choose the destination and all the NFTs you want to send. Sign the transaction. And that's it! Transfer your NFTs in another wallet, easily and quickly."
			/>
			<PageBody>
				<BodyHeader title={`Step ${step}`} subtitle={steps[step - 1]} />

				{step === 1 && (
					<Step1
						userInput={userInput}
						setUserInput={setUserInput}
						nfts={nfts}
						setNfts={setNfts}
						nextStep={nextStep}
					/>
				)}
				{step === 2 && (
					<Step2
						userInput={userInput}
						nfts={nfts}
						prevStep={prevStep}
						nextStep={nextStep}
						setTransactionId={setTransactionId}
					/>
				)}
				{step === 3 && (
					<Step3
						prevStep={prevStep}
						nfts={nfts}
						transactionStatus={transactionStatus}
					/>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default NftMultiSend;
