import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";
import { getContracts } from "config";
import { Step1, Step2, Step3 } from "components/pages/nftDistribution";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import BannedPage from "components/BannedPage";
import bannedAddresses from "bannedAddresses";

const NftDistribution = () => {
	const steps = [
		"Prepare your distribution",
		"Check the Distribution Summary",
		"Done!",
	];
	const [step, setStep] = useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transactionId, setTransactionId] = useState(null);
	const transactionStatus = useTrackTransactionStatus({ transactionId });

	const account = useGetAccountInfo();
	const [contracts, setContracts] = useState([]);

	const [userInput, setUserInput] = useState({
		list: "",
		contract: null,
	});
	const [nftsToSend, setNftsToSend] = useState([]);

	useEffect(() => {
		getContracts(account.address, apiAddress).then((contracts) => {
			setContracts(contracts);
			setUserInput((prev) => ({
				...prev,
				contract: (contracts[0] as any).address,
			}));
		});
	}, [account.address, apiAddress]);

	useEffect(() => {
		if (transactionStatus.isPending) {
			nextStep();
		}
	}, [transactionStatus.isPending]);

	if (bannedAddresses.includes(account.address)) {
		return <BannedPage />;
	}

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						Distribute your&nbsp;
						<span className="text-primary">
							NFTs to lots of wallets
						</span>{" "}
						in a simple and fast way
					</>
				}
				subtitle="You can distribute hundreds of NFTs in a single transaction!
                Insert the list of addresses and NFTs, sign the transaction, and it's done!
                Multiple collections in the same tx are supported!"
			/>
			<PageBody>
				<BodyHeader title={`Step ${step}`} subtitle={steps[step - 1]} />

				{step === 1 && (
					<Step1
						userInput={userInput}
						setUserInput={setUserInput}
						setNftsToSend={setNftsToSend}
						contracts={contracts}
						nextStep={nextStep}
					/>
				)}
				{step === 2 && (
					<Step2
						userInput={userInput}
						nftsToSend={nftsToSend}
						contracts={contracts}
						prevStep={prevStep}
						nextStep={nextStep}
						setTransactionId={setTransactionId}
					/>
				)}
				{step === 3 && (
					<Step3
						nftsToSend={nftsToSend}
						prevStep={prevStep}
						transactionStatus={transactionStatus}
					/>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default NftDistribution;
