import React, { useState } from "react";
import ErrorMessage from "components/ErrorMessage";
import NftViewer from "components/NftViewer";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import { Address } from "@multiversx/sdk-core";
import { burnAddress } from "config";

const Step1 = ({ userInput, setUserInput, nextStep, nfts, setNfts }) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const tortugaApiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [errorMessage, setErrorMessage] = useState("");
	const inputChange = (index, e) => {
		const newNfts = [...nfts];
		newNfts[index].toSend = e.target.checked;
		setNfts(newNfts);
	};

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			let errors = "";

			try {
				const receiver = userInput.receiver;
				let address;

				//check if receiver is herotag
				if (receiver.length !== 62) {
					address =
						await tortugaApiNetworkProvider.getAccountFromHerotag(
							receiver
						);
				} else {
					address = receiver;
				}

				//address
				const receiverObj = Address.fromBech32(address);

				setUserInput({
					...userInput,
					receiverObj,
				});
			} catch (error: any) {
				errors += `${error.message}\n`;
			}

			if (errors.length > 0) {
				throw Error(errors);
			}

			nextStep();
		} catch (err: any) {
			setErrorMessage(err.message);
		}
	};

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<form onSubmit={submitForm}>
				<div className="form-group">
					<label htmlFor="amountInput">Destination:</label>
					<input
						id="amountInput"
						type="text"
						className="form-control form-control-lg mb-2"
						placeholder="erd1... or herotag"
						value={userInput.receiver}
						onChange={(e) =>
							setUserInput({ receiver: e.target.value })
						}
					/>
					<button
						className="btn btn-primary px-4"
						onClick={(e) => {
							e.preventDefault();
							setUserInput({
								receiver: burnAddress,
							});
						}}
					>
						Use burn address 🔥️️️️️️
					</button>
				</div>

				<div className="form-group">
					<label htmlFor="selectToken">
						Select the NFTs you want to send:
					</label>
					<div className="nft-container">
						{nfts.map((nft, index) => (
							<NftViewer
								nft={nft}
								callback={(state) => inputChange(index, state)}
							/>
						))}
					</div>
				</div>

				<div className="d-flex flex-row">
					<div className="flex-grow-1" />

					<div>
						<input
							className="btn btn-primary py-2 px-4 mx-2"
							type="submit"
							value="Next"
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Step1;
