import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";
import { getContracts } from "config";
import { Step1, Step2, Step3 } from "components/pages/bulkTransaction";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import BannedPage from "components/BannedPage";
import bannedAddresses from "bannedAddresses";

const BulkTransaction = () => {
	const steps = [
		"Prepare your Bulk Transaction",
		"Check the Transaction Summary",
		"Done!",
	];
	const [step, setStep] = useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transactionId, setTransactionId] = useState(null);
	const transactionStatus = useTrackTransactionStatus({ transactionId });

	const account = useGetAccountInfo();
	const [contracts, setContracts] = useState([]);
	const [tokens, setTokens] = useState([]);

	const [userInput, setUserInput] = useState({
		tokens: ["EGLD"],
		sameAmount: false,
		amounts: [""],
		list: "",
		contract: null,
	});
	const [txsToSend, setTxsToSend] = useState([]);

	useEffect(() => {
		(async () => {
			setTokens(
				await apiNetworkProvider.getAccountAllTokens(account.address)
			);
		})();
	}, []);

	useEffect(() => {
		getContracts(account.address, apiAddress).then((contracts) => {
			setContracts(contracts);
			setUserInput((prev) => ({
				...prev,
				contract: (contracts[0] as any).address,
			}));
		});
	}, [account.address, apiAddress]);

	useEffect(() => {
		if (transactionStatus.isPending) {
			nextStep();
		}
	}, [transactionStatus.isPending]);

	if (bannedAddresses.includes(account.address)) {
		return <BannedPage />;
	}

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						The fastest and easiest way to&nbsp;
						<span className="text-primary">
							send bulk transactions
						</span>{" "}
						on the MultiversX Blockchain
					</>
				}
				subtitle="Insert the list of transactions and send EGLD or ESDTs to thousands of wallets in seconds, 
                without programming knowledge and using your preffered login method with maximum security, 
                without having to save a key for your wallet."
			/>
			<PageBody>
				<BodyHeader title={`Step ${step}`} subtitle={steps[step - 1]} />

				{step === 1 && (
					<Step1
						userInput={userInput}
						setUserInput={setUserInput}
						setTxsToSend={setTxsToSend}
						tokens={tokens}
						contracts={contracts}
						nextStep={nextStep}
					/>
				)}
				{step === 2 && (
					<Step2
						userInput={userInput}
						txsToSend={txsToSend}
						tokens={tokens}
						contracts={contracts}
						prevStep={prevStep}
						nextStep={nextStep}
						setTransactionId={setTransactionId}
					/>
				)}
				{step === 3 && (
					<Step3
						prevStep={prevStep}
						txsToSend={txsToSend}
						userInput={userInput}
						transactionStatus={transactionStatus}
					/>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default BulkTransaction;
