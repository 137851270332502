import React, { useState } from "react";
import FileInput from "components/FileInput";
import ErrorMessage from "components/ErrorMessage";
import InfoIcon from "components/Icons/InfoIcon";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import { Address } from "@multiversx/sdk-core";

const Step1 = ({
	userInput,
	setUserInput,
	setParticipantsToSend,
	tokens,
	contracts,
	nextStep,
}) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const tortugaApiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const privateContracts = contracts.filter((c) => !c.public);
	const setUserInputText = (t) =>
		setUserInput((prev) => ({ ...prev, list: t }));

	const [errorMessage, setErrorMessage] = useState("");

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			let participants = [];
			let errors = "";

			const lines = userInput.list.trim().split(/[\r\n]+/);

			await Promise.all(
				lines.map(async (line, i) => {
					try {
						let address;

						//check if receiver is herotag
						if (line.length !== 62) {
							address =
								await tortugaApiNetworkProvider.getAccountFromHerotag(
									line
								);
						} else {
							address = line;
						}

						//address
						const addressObj = Address.fromBech32(address);

						//push to array
						participants.push(addressObj);
					} catch (error: any) {
						errors += `Line ${i + 1}: ${error.message}\n`;
					}
				})
			);

			if (errors.length > 0) {
				throw Error(errors);
			}

			setParticipantsToSend(participants);
			nextStep();
		} catch (error: any) {
			setErrorMessage(error.message);
		}
	};

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<form onSubmit={submitForm}>
				{userInput.prizes.map((prize, i) => (
					<div className="row">
						<h3 className="text-primary">
							Prize for place n.{i + 1}
						</h3>

						<div className="form-group col-md">
							<label htmlFor="selectToken">
								Select the token or NFT you want to send to the
								winner:
							</label>
							<select
								id="selectToken"
								className="form-control form-control-lg"
								value={userInput.prizes[i].token}
								onChange={(e) =>
									setUserInput((prev) => ({
										...prev,
										prizes: prev.prizes.map((prize, j) => {
											if (j === i) {
												return {
													...prize,
													token: e.target.value,
												};
											}
											return prize;
										}),
									}))
								}
							>
								{tokens.map((token) => (
									<option value={token.identifier}>
										{token.name} ({token.identifier})
									</option>
								))}
							</select>
						</div>

						{tokens.find(
							(token) => token.identifier === prize.token
						)?.type !== "NonFungibleESDT" && (
							<div className="form-group col-md">
								<label htmlFor="amountInput">
									Choose the amount that the winner will
									receive:
								</label>
								<input
									id="amountInput"
									type="text"
									className="form-control form-control-lg"
									placeholder="Amount"
									value={userInput.prizes[i].amount}
									onChange={(e) =>
										setUserInput((prev) => ({
											...prev,
											prizes: prev.prizes.map(
												(prize, j) => {
													if (j === i) {
														return {
															...prize,
															amount: e.target
																.value,
														};
													}
													return prize;
												}
											),
										}))
									}
								/>
							</div>
						)}
						<hr />
					</div>
				))}

				<button
					className="btn btn-primary py-2 px-4 mb-3"
					onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
						e.preventDefault();
						setUserInput({
							...userInput,
							prizes: [
								...userInput.prizes,
								{
									token: tokens[0].identifier,
									amount: "",
								},
							],
						});
					}}
				>
					+ Add prize
				</button>

				{privateContracts.length > 0 ? (
					<div className="form-group">
						<label htmlFor="selectContract">
							Select the contract you want to use:
						</label>
						<select
							id="selectContract"
							className="form-control form-control-lg"
							value={userInput.contract}
							onChange={(e) =>
								setUserInput({
									...userInput,
									contract: e.target.value,
								})
							}
						>
							{contracts.map((contract) => (
								<option value={contract.address}>
									xBulk: {contract.name}
								</option>
							))}
						</select>
					</div>
				) : null}

				<div className="form-group mb-5">
					<label htmlFor="listTx">
						Enter the list of the participants (1.000 addresses
						maximum):
					</label>
					<textarea
						id="listTx"
						placeholder="address/herotag"
						className="form-control form-control-lg"
						required
						value={userInput.list}
						onChange={(e) =>
							setUserInput({
								...userInput,
								list: e.target.value,
							})
						}
					/>
					<div className="text-light mt-2">
						<InfoIcon />
						<span className="ms-2">
							Insert one address per line
						</span>
					</div>
				</div>

				<div className="d-flex flex-row">
					<FileInput callback={setUserInputText} />

					<div className="flex-grow-1" />

					<div>
						<input
							className="btn btn-primary py-2 px-4 mx-2"
							type="submit"
							value="Next"
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Step1;
