import React, { useState, useEffect } from "react";
import { sendTransactions } from "utils/functions";
import { getFactoryContract } from "config";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";
import {
	ContractFunction,
	ContractCallPayloadBuilder,
	BytesValue,
} from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";
import { shortenAddress } from "utils/functions";
import {
	Address,
	SmartContract,
	AbiRegistry,
	ResultsParser,
} from "@multiversx/sdk-core";
import { ProxyNetworkProvider } from "@multiversx/sdk-network-providers";
import xBulkFactoryAbi from "xbulkfactory.abi.json";

const Step2 = ({ userInput, prevStep, nextStep, setTransactionId }) => {
	const account = useGetAccountInfo();
	const {
		network: { apiAddress, egldLabel },
	} = useGetNetworkConfig();

	const factoryContract = getFactoryContract();
	const [deployPrice, setDeployPrice] = useState(
		new BigNumber("500000000000000000")
	);

	useEffect(() => {
		(async () => {
			const xBulkFactoryContract = new SmartContract({
				address: new Address(factoryContract),
				abi: AbiRegistry.create(xBulkFactoryAbi),
			});

			const interaction = xBulkFactoryContract.methods.getDeployPrice();
			const query = interaction.check().buildQuery();
			const networkProvider = new ProxyNetworkProvider(apiAddress);
			const queryResponse = await networkProvider.queryContract(query);
			const typedBundle = new ResultsParser().parseQueryResponse(
				queryResponse,
				interaction.getEndpoint()
			);
			if (typedBundle.firstValue) {
				setDeployPrice(typedBundle.firstValue.valueOf());
			}
		})();
	}, [apiAddress, factoryContract]);

	const deployContract = async () => {
		const payload = new ContractCallPayloadBuilder()
			.setFunction(new ContractFunction("newContract"))
			.setArgs([BytesValue.fromUTF8(userInput)])
			.build()
			.toString();

		sendTransactions(
			{
				value: deployPrice,
				data: payload.toString(),
				receiver: factoryContract,
				gasLimit: 20_000_000,
			},
			{
				processingMessage: "Creating contract",
				errorMessage: "An error has occured during the creation",
				successMessage: "Contract created successfully!",
			}
		).then(({ sessionId }) => {
			setTransactionId(sessionId);
		});
	};

	return (
		<>
			<div className="text-center">
				<h2 className="fw-bold mb-4">
					Contract Name:&nbsp;
					<span className="text-primary">xBulk: {userInput}</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Deploy cost:&nbsp;
					<span className="text-primary">
						<FormatAmount
							value={deployPrice.toString()}
							token={egldLabel}
							showLastNonZeroDecimal={true}
							digits={0}
						/>
					</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Owner:&nbsp;
					<span className="text-primary">
						{shortenAddress(account.address)}
					</span>
				</h2>

				<h4 className="fw-bold mb-4 mt-5">
					You are about to deploy a private contract for xBulk.
					<span className="text-primary">
						&nbsp;Only you will be able to use it&nbsp;
					</span>
					from the address {shortenAddress(account.address, 4)}, and
					<span className="text-primary">
						&nbsp;your users will be aware that transactions are
						originating from you.
					</span>
					<br />
					You will have the flexibility to choose the contract for
					each transaction.
					<br />
					The chosen contract's name will undergo approval by the
					MultiversX team. Once approved,&nbsp;
					<span className="text-primary">
						the name will be displayed in xPortal and xExplorer.
					</span>
					&nbsp;Please note that contract name approval and timeframe
					are beyond our control.
				</h4>
				<h4 className="fw-bold mb-4">
					If you have any questions, please contact us on&nbsp;
					<a
						href="https://t.me/tortugastaking"
						target="_blank"
						rel="noreferrer"
					>
						Telegram
					</a>
					.
				</h4>
			</div>

			<div className="text-center my-5">
				<button
					className="btn btn-secondary py-2 px-4 mx-2"
					onClick={prevStep}
				>
					Back
				</button>
				<button
					className="btn btn-primary py-2 px-4 mx-2"
					onClick={deployContract}
				>
					Send
				</button>
			</div>
		</>
	);
};

export default Step2;
