import React from "react";
import PageTemplate from "components/PageTemplate";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";

export default function BannedPage() {
	return (
		<PageTemplate>
			<PageBody>
				<BodyHeader
					title={"Sorry, you can't use this tool :("}
					subtitle={
						"Don't worry, everyone makes mistakes. To err is human, to persist is of the Devil ;)"
					}
				/>
			</PageBody>
		</PageTemplate>
	);
}
