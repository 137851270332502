import React, { useState, useEffect } from "react";
import { maxTxPerCall } from "utils/constants";
import { sendTransactions } from "utils/functions";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";
import {
	Address,
	AddressValue,
	ContractFunction,
	TokenPayment,
	TransactionPayload,
	BytesValue,
	BigUIntValue,
	U8Value,
	U64Value,
	ContractCallPayloadBuilder,
} from "@multiversx/sdk-core";
import { BigNumber } from "bignumber.js";

const Step2 = ({
	userInput,
	txsToSend,
	prevStep,
	nextStep,
	tokens,
	contracts,
	setTransactionId,
}) => {
	const account = useGetAccountInfo();

	const [txsObjects, setTxsObjects] = useState<{
		[key: string]: {
			tokenPayment: TokenPayment;
			destination: Address;
		}[];
	}>();
	const [totalToSend, setTotalToSend] = useState<{
		[key: string]: TokenPayment;
	}>();
	const contractToUse = contracts.find(
		(contract) => contract.address === userInput.contract
	);

	const nCalls: {
		[key: string]: number;
	} = txsObjects
		? Object.keys(txsObjects).reduce((acc, token) => {
				acc[token] = Math.ceil(txsObjects[token].length / maxTxPerCall);
				return acc;
		  }, {})
		: {};

	const createTokenPayment = (tokenIdentifier, amount) => {
		const token = tokens.find(
			(token) => token.identifier === tokenIdentifier
		);

		if (!token) {
			return TokenPayment.egldFromAmount(amount);
		} else if (token.type === "FungibleESDT") {
			return TokenPayment.fungibleFromAmount(
				token.identifier,
				amount,
				token.decimals
			);
		} else {
			return TokenPayment.metaEsdtFromAmount(
				token.identifier.split("-")[0] +
					"-" +
					token.identifier.split("-")[1],
				token.nonce,
				amount,
				token.decimals
			);
		}
	};

	useEffect(() => {
		let txsObjects: {
			[key: string]: {
				tokenPayment: TokenPayment;
				destination: Address;
			}[];
		} = {};
		let totalToSend: {
			[key: string]: BigNumber;
		} = {};

		userInput.tokens.forEach((token, i) => {
			totalToSend[token] = new BigNumber(0);
			txsObjects[token] = [];
		});

		txsToSend.forEach((tx) => {
			userInput.tokens.forEach((token, i) => {
				const txTokenAmount = userInput.sameAmount
					? userInput.amounts[i]
					: tx.amounts[i];
				if (txTokenAmount === "" || txTokenAmount === "0") {
					return;
				}

				const tokenPayment = createTokenPayment(token, txTokenAmount);

				txsObjects[token].push({
					tokenPayment,
					destination: tx.address,
				});
				totalToSend[token] = totalToSend[token].plus(
					tokenPayment.amountAsBigInteger
				);
			});
		});

		setTxsObjects(txsObjects);
		setTotalToSend(
			Object.keys(totalToSend).reduce((acc, token) => {
				acc[token] = createTokenPayment(
					token,
					totalToSend[token].div(
						10 **
							(tokens.find((t) => t.identifier === token)
								?.decimals ?? 18)
					)
				);
				return acc;
			}, {})
		);
	}, [txsToSend]);

	const sendBulkTransaction = async () => {
		let calls = [];

		Object.keys(txsObjects).forEach((tokenIdentifier) => {
			const token = tokens.find(
				(token) => token.identifier === tokenIdentifier
			);
			const tokenTxsObjects = txsObjects[tokenIdentifier];

			const nTxPerCall = Math.ceil(
				tokenTxsObjects.length / nCalls[tokenIdentifier]
			);

			for (let i = 0; i < tokenTxsObjects.length; i += nTxPerCall) {
				const callTxs = tokenTxsObjects.slice(i, i + nTxPerCall);
				let callAmount = new BigNumber(0);
				let callArgs: any[] = [];

				callTxs.forEach((tx) => {
					callAmount = callAmount.plus(
						tx.tokenPayment.amountAsBigInteger
					);
					callArgs.push(new AddressValue(tx.destination));
					if (!userInput.sameAmount) {
						callArgs.push(
							new BigUIntValue(tx.tokenPayment.valueOf())
						);
					}
				});

				if (token) {
					const tokenParts = token.identifier.split("-");
					const tokenIdentifier = tokenParts[0] + "-" + tokenParts[1];

					const payload = new ContractCallPayloadBuilder()
						.setFunction(
							new ContractFunction("MultiESDTNFTTransfer")
						)
						.setArgs([
							new AddressValue(
								new Address(contractToUse.address)
							),
							new U8Value(1),
							BytesValue.fromUTF8(tokenIdentifier),
							new U64Value(
								tokenParts.length === 3 ? tokenParts[2] : 0
							),
							new BigUIntValue(callAmount.valueOf()),
							BytesValue.fromUTF8(
								userInput.sameAmount
									? "bulksendSameAmount"
									: "bulksend"
							),
							...callArgs,
						])
						.build();

					calls.push({
						value: 0,
						data: payload.toString(),
						receiver: account.address,
						gasLimit: Math.min(
							2_600_000 +
								(userInput.sameAmount ? 1_600_000 : 1_600_000) *
									callTxs.length,
							600_000_000
						),
					});
				} else {
					const payload = new ContractCallPayloadBuilder()
						.setFunction(
							new ContractFunction(
								userInput.sameAmount
									? "bulksendSameAmount"
									: "bulksend"
							)
						)
						.setArgs(callArgs)
						.build();

					calls.push({
						value: callAmount,
						data: payload.toString(),
						receiver: contractToUse.address,
						gasLimit: Math.min(
							1_300_000 +
								(userInput.sameAmount ? 1_450_000 : 1_450_000) *
									callTxs.length,
							600_000_000
						),
					});
				}
			}
		});

		sendTransactions(calls, {
			processingMessage: "Processing Bulk transactions",
			errorMessage: "An error has occured during transactions",
			successMessage: "Bulk transactions successful",
		}).then(({ sessionId }) => {
			setTransactionId(sessionId);
		});
	};

	return (
		<>
			<div className="text-center">
				<h2 className="fw-bold mb-4">
					Number of transactions:&nbsp;
					<span className="text-primary">
						{txsObjects &&
							Object.keys(txsObjects).reduce((acc, token) => {
								acc += txsObjects[token].length;
								return acc;
							}, 0)}
					</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Number of contract calls to sign:&nbsp;
					<span className="text-primary">
						{Object.keys(nCalls).reduce((acc, token) => {
							acc += nCalls[token];
							return acc;
						}, 0)}
					</span>
				</h2>
				{userInput.tokens.map((token, i) => (
					<h2 className="fw-bold mb-4">
						Total {token} to send:&nbsp;
						<span className="text-primary">
							<FormatAmount
								value={totalToSend?.[
									token
								].amountAsBigInteger.toString(10)}
								token={totalToSend?.[token].tokenIdentifier}
								decimals={totalToSend?.[token].numDecimals}
								showLastNonZeroDecimal={true}
								digits={0}
							/>
						</span>
					</h2>
				))}

				<h2 className="fw-bold">
					Contract to use:&nbsp;
					<span className="text-primary">
						xBulk: {contractToUse.name}
					</span>
				</h2>
			</div>

			<div className="text-center my-5">
				<button
					className="btn btn-secondary py-2 px-4 mx-2"
					onClick={prevStep}
				>
					Back
				</button>
				<button
					className="btn btn-primary py-2 px-4 mx-2"
					onClick={sendBulkTransaction}
				>
					Send
				</button>
			</div>

			<table className="table table-mvx">
				<thead>
					<tr>
						<th scope="col" colSpan={3}>
							List of transactions to send
						</th>
					</tr>
					<tr>
						<th scope="col">#</th>
						<th scope="col">ADDRESS</th>
						<th scope="col">AMOUNT</th>
					</tr>
				</thead>

				<tbody>
					{txsObjects &&
						Object.keys(txsObjects).map((token, i) => (
							<>
								<tr key={i}>
									<th
										scope="row"
										colSpan={3}
										className="text-center text-primary"
									>
										{token} transactions
									</th>
								</tr>

								{txsObjects[token].map((tx, i) => (
									<tr key={i}>
										<th scope="row">{i + 1}.</th>
										<td>{tx.destination.bech32()}</td>
										<td>
											<FormatAmount
												value={tx?.tokenPayment.amountAsBigInteger.toString(
													10
												)}
												token={
													tx?.tokenPayment
														?.tokenIdentifier
												}
												decimals={
													tokens.find(
														(t) =>
															t.identifier ===
															token
													)?.decimals ?? 18
												}
												showLastNonZeroDecimal={true}
												digits={0}
											/>
										</td>
									</tr>
								))}
							</>
						))}
				</tbody>
			</table>
		</>
	);
};

export default Step2;
