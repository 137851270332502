import React from "react";

const BodyHeader = ({ title, subtitle }) => {
	return (
		<div className="mb-5">
			<h1 className="text-primary fw-bold mb-1">{title}</h1>
			<h1 className="fw-bold">{subtitle}</h1>
		</div>
	);
};

export default BodyHeader;
