import React, { useState } from "react";
import FileInput from "components/FileInput";
import ErrorMessage from "components/ErrorMessage";
import InfoIcon from "components/Icons/InfoIcon";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import { Address } from "@multiversx/sdk-core";

const Step1 = ({
	userInput,
	setUserInput,
	nextStep,
	setTxsToSend,
	tokens,
	contracts,
}) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const tortugaApiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const privateContracts = contracts.filter((c) => !c.public);
	const setUserInputText = (t) =>
		setUserInput((prev) => ({ ...prev, list: t }));

	const [errorMessage, setErrorMessage] = useState("");

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			let transactionsToSend: any[] = [];
			let errors = "";

			const lines = userInput.list.trim().split(/[\r\n]+/);

			await Promise.all(
				lines.map(async (line, i) => {
					try {
						const lineComponents = line.split(";");
						const receiver = lineComponents[0];
						const amounts = lineComponents.slice(1);
						let address;

						if (
							amounts.length !== userInput.tokens.length &&
							!userInput.sameAmount
						) {
							throw Error(
								`You are trying to send ${userInput.tokens.length} tokens but only specified ${amounts.length} amounts`
							);
						}

						//check if receiver is herotag
						if (receiver.length !== 62) {
							address =
								await tortugaApiNetworkProvider.getAccountFromHerotag(
									receiver
								);
						} else {
							address = receiver;
						}

						//address
						const addressObj = Address.fromBech32(address);

						//amount
						if (!userInput.sameAmount) {
							amounts.forEach((amount, i) => {
								if (amount !== "" && isNaN(Number(amount))) {
									throw Error(
										`"${amount}" is not a valid number`
									);
								}
							});
						}

						//push to array
						transactionsToSend.push(
							userInput.sameAmount
								? {
										address: addressObj,
								  }
								: {
										address: addressObj,
										amounts,
								  }
						);
					} catch (error: any) {
						errors += `Line ${i + 1}: ${error.message}\n`;
					}
				})
			);

			if (errors.length > 0) {
				throw Error(errors);
			}

			setTxsToSend(transactionsToSend);
			nextStep();
		} catch (err: any) {
			setErrorMessage(err.message);
		}
	};

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<form onSubmit={submitForm}>
				<div className="form-group">
					<div className="form-group form-check">
						<input
							type="checkbox"
							className="form-control form-check-input"
							id="sameAmount"
							checked={userInput.sameAmount}
							onChange={(e) =>
								setUserInput({
									...userInput,
									sameAmount: e.target.checked,
								})
							}
						/>
						<label
							className="form-check-label ms-2"
							htmlFor="sameAmount"
						>
							Use the same amount for each transaction
						</label>
					</div>
				</div>

				{userInput.tokens.map((t, i) => (
					<div className="form-group">
						<label htmlFor="selectToken">
							Token to send n.{i + 1}:
						</label>

						<div className="input-group mb-2">
							<select
								id="selectToken"
								className="form-control form-control-lg"
								value={t}
								onChange={(e) =>
									setUserInput((prev) => ({
										...prev,
										tokens: prev.tokens.map((token, j) => {
											if (j === i) {
												return e.target.value;
											}
											return token;
										}),
									}))
								}
							>
								<option value="EGLD">EGLD</option>
								{tokens.map((token) => (
									<option value={token.identifier}>
										{token.name} ({token.identifier})
									</option>
								))}
							</select>

							{userInput.tokens.length > 1 && (
								<button
									className="btn btn-lg btn-danger"
									onClick={(
										e: React.MouseEvent<HTMLButtonElement>
									) => {
										e.preventDefault();
										if (userInput.tokens.length === 1) {
											return;
										}

										setUserInput((prev) => ({
											...prev,
											tokens: prev.tokens.filter(
												(token, j) => j !== i
											),
											amounts: prev.amounts.filter(
												(amount, j) => j !== i
											),
										}));
									}}
								>
									remove
								</button>
							)}
						</div>

						{userInput.sameAmount && (
							<div>
								<label htmlFor="amountInput">
									Amount of {t} for each transaction:
								</label>
								<input
									id="amountInput"
									type="text"
									className="form-control form-control-lg"
									placeholder="Amount"
									value={userInput.amounts[i]}
									onChange={(e) =>
										setUserInput((prev) => ({
											...prev,
											amounts: prev.amounts.map(
												(amount, j) => {
													if (j === i) {
														return e.target.value;
													}
													return amount;
												}
											),
										}))
									}
								/>
							</div>
						)}
					</div>
				))}

				<button
					className="btn btn-primary py-2 px-4 mb-3"
					onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
						e.preventDefault();
						setUserInput((prev) => ({
							...prev,
							tokens: [...prev.tokens, "EGLD"],
							amounts: [...prev.amounts, ""],
						}));
					}}
				>
					+ Add token
				</button>

				{privateContracts.length > 0 ? (
					<div className="form-group">
						<label htmlFor="selectContract">
							Select the contract you want to use:
						</label>
						<select
							id="selectContract"
							className="form-control form-control-lg"
							value={userInput.contract}
							onChange={(e) =>
								setUserInput({
									...userInput,
									contract: e.target.value,
								})
							}
						>
							{contracts.map((contract) => (
								<option value={contract.address}>
									xBulk: {contract.name}
								</option>
							))}
						</select>
					</div>
				) : null}

				<div className="form-group mb-5">
					<label htmlFor="listTx">
						Enter the list of the transactions you want to send:
					</label>
					<textarea
						id="listTx"
						placeholder={
							"address/herotag" +
							(userInput.sameAmount
								? ""
								: ";" +
								  userInput.tokens
										.map((t, i) => t + " amount")
										.join(";"))
						}
						className="form-control form-control-lg"
						required
						value={userInput.list}
						onChange={(e) =>
							setUserInput({
								...userInput,
								list: e.target.value,
							})
						}
					/>
					<div className="text-light mt-2">
						<InfoIcon />
						<span className="ms-2">
							Insert one address per line
						</span>
					</div>
					<div className="text-light mt-1">
						<InfoIcon />
						<span className="ms-2">
							Use the dot [.] as decimal separator
						</span>
					</div>
				</div>

				<div className="d-flex flex-row">
					<FileInput callback={setUserInputText} />

					<div className="flex-grow-1" />

					<div>
						<input
							className="btn btn-primary py-2 px-4 mx-2"
							type="submit"
							value="Next"
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Step1;
