import React, { useState } from "react";
import ErrorMessage from "components/ErrorMessage";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";

const Step1 = ({ userInput, setUserInput, nextStep }) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const tortugaApiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [errorMessage, setErrorMessage] = useState("");

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// TODO check if user input is valid
		nextStep();
	};

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<form onSubmit={submitForm}>
				<div className="form-group">
					<label htmlFor="amountInput">Contract name:</label>
					<div className="input-group mb-3">
						<span className="input-group-text">xBulk:</span>
						<input
							id="nameInput"
							type="text"
							className="form-control form-control-lg"
							placeholder="Name"
							value={userInput}
							onChange={(e) => setUserInput(e.target.value)}
						/>
					</div>
				</div>

				<div className="d-flex flex-row">
					<div className="flex-grow-1" />

					<div>
						<input
							className="btn btn-primary py-2 px-4 mx-2"
							type="submit"
							value="Next"
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Step1;
