import React, { useState } from "react";
import FileInput from "components/FileInput";
import ErrorMessage from "components/ErrorMessage";
import InfoIcon from "components/Icons/InfoIcon";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import { Address } from "@multiversx/sdk-core";

const Step1 = ({
	userInput,
	setUserInput,
	setNftsToSend,
	nextStep,
	contracts,
}) => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const tortugaApiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const privateContracts = contracts.filter((c) => !c.public);
	const setUserInputText = (t) =>
		setUserInput((prev) => ({ ...prev, list: t }));
	const [errorMessage, setErrorMessage] = useState("");

	const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			var nftsToSend = [];
			var errors = "";

			const lines = userInput.list.trim().split(/[\r\n]+/);

			await Promise.all(
				lines.map(async (line, i) => {
					try {
						const [receiver, identifier] = line.split(";");
						let address;

						//check if receiver is herotag
						if (receiver.length !== 62) {
							address =
								await tortugaApiNetworkProvider.getAccountFromHerotag(
									receiver
								);
						} else {
							address = receiver;
						}

						//address
						const addressObj = Address.fromBech32(address);

						//identifier
						//TODO: check if identifier is valid

						//push to array
						nftsToSend.push({
							address: addressObj,
							identifier: identifier.trim(),
						});
					} catch (error: any) {
						errors += `Line ${i + 1}: ${error.message}\n`;
					}
				})
			);

			if (errors.length > 0) {
				throw Error(errors);
			}

			setNftsToSend(nftsToSend);
			nextStep();
		} catch (error: any) {
			setErrorMessage(error.message);
		}
	};

	return (
		<>
			<ErrorMessage message={errorMessage} />
			<form onSubmit={submitForm}>
				{privateContracts.length > 0 ? (
					<div className="form-group">
						<label htmlFor="selectContract">
							Select the contract you want to use:
						</label>
						<select
							id="selectContract"
							className="form-control form-control-lg"
							value={userInput.contract}
							onChange={(e) =>
								setUserInput({
									...userInput,
									contract: e.target.value,
								})
							}
						>
							{contracts.map((contract) => (
								<option value={contract.address}>
									xBulk: {contract.name}
								</option>
							))}
						</select>
					</div>
				) : null}

				<div className="form-group mb-5">
					<label htmlFor="listNfts">
						Enter the list of the NFTs and addresses you want to
						send:
					</label>
					<textarea
						id="listNfts"
						placeholder="Address/herotag;NFT identifier (e.g. ABC-1a9c7d-05dc)"
						className="form-control form-control-lg"
						required
						value={userInput.list}
						onChange={(e) =>
							setUserInput({
								...userInput,
								list: e.target.value,
							})
						}
					/>
					<div className="text-light mt-2">
						<InfoIcon />
						<span className="ms-2">
							Insert one address per line
						</span>
					</div>
				</div>

				<div className="d-flex flex-row">
					<FileInput callback={setUserInputText} />

					<div className="flex-grow-1" />

					<div>
						<input
							className="btn btn-primary py-2 px-4 mx-2"
							type="submit"
							value="Next"
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default Step1;
