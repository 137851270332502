import React from "react";
import { BallSpinner } from "react-spinners-kit";

const Step3 = ({ prevStep, userInput, transactionStatus }) => {
	return (
		<>
			{transactionStatus.isPending && (
				<>
					<BallSpinner color="#23f7dd" />
					<p className="fs-2 mt-3 fw-bold text-primary">
						Get ready to embark on a cosmic creation journey! We're brewing up a
						marvelous private contract just for you. Sit back, relax, and
						prepare to witness the birth of your very own contract masterpiece!
					</p>
				</>
			)}

			{transactionStatus.isSuccessful && (
				<>
					<p className="fs-4 mb-1">Contract created with name:</p>
					<p className="fs-2 fw-bold text-primary">xBulk: {userInput}</p>

					<p className="fs-5 text-light mt-4 mb-2">
						The name will be submitted to the MultiversX team for approval.
						<br />
						You will have the flexibility to choose the contract for each
						transaction.
						<br />
						Thank you for choosing xBulk!
					</p>
				</>
			)}

			{transactionStatus.isFailed && (
				<>
					<p className="fs-5 mt-6 mb-2 text-primary">
						Oops... Looks like something didn't quite work its magic. Here is
						what the blockchain says: {transactionStatus.errorMessage}
					</p>
					<p className="fs-5">
						Please check your transaction and try again. If the issue persists,
						don't hesitate to contact us for further assistance. Our team is
						here to help unravel the mystery and get your transactions back on
						the enchanted path!
					</p>
				</>
			)}

			<div className="text-center mt-5">
				<button className="btn btn-secondary py-2 px-4" onClick={prevStep}>
					Back
				</button>
			</div>
		</>
	);
};

export default Step3;
