import React, { useState, useEffect } from "react";
import { sendTransactions } from "utils/functions";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { FormatAmount } from "@multiversx/sdk-dapp/UI";
import { BigNumber } from "bignumber.js";
import {
	Address,
	AddressValue,
	TokenPayment,
	ContractFunction,
	TransferTransactionsFactory,
	GasEstimator,
} from "@multiversx/sdk-core";
import { string2hex } from "utils/functions";

const Step2 = ({
	userInput,
	participantsToSend,
	tokens,
	contracts,
	prevStep,
	nextStep,
	setTransactionId,
}) => {
	const account = useGetAccountInfo();
	const contractToUse = contracts.find(
		(contract) => contract.address === userInput.contract
	);

	const [prizes, setPrizes] = useState([]);

	const createTokenPayment = (tokenIdentifier, amount) => {
		const token = tokens.find(
			(token) => token.identifier === tokenIdentifier
		);

		if (token.type === "FungibleESDT") {
			return TokenPayment.fungibleFromAmount(
				token.identifier,
				amount,
				token.decimals
			);
		} else if (token.type === "MetaESDT") {
			return TokenPayment.metaEsdtFromAmount(
				token.identifier.split("-")[0] +
					"-" +
					token.identifier.split("-")[1],
				token.nonce,
				amount,
				token.decimals
			);
		} else {
			return TokenPayment.nonFungible(token.collection, token.nonce);
		}
	};

	useEffect(() => {
		setPrizes(
			userInput.prizes.map((prize) =>
				createTokenPayment(prize.token, prize.amount)
			)
		);
	}, [userInput.prizes]);

	const sendDrawTransaction = async () => {
		let payload = new TransferTransactionsFactory(new GasEstimator())
			.createMultiESDTNFTTransfer({
				tokenTransfers: prizes,
				destination: new Address(contractToUse.address),
				sender: new Address(account.address),
				chainID: "1",
			})
			.getData()
			.toString();

		payload += "@";
		payload += string2hex("draw");
		payload += "@";
		payload += participantsToSend.map((a) => a.hex()).join("@");

		const tx = {
			value: 0,
			data: payload.toString(),
			receiver: account.address,
			gasLimit: Math.min(
				12_000_000 + 750_000 * participantsToSend.length,
				600_000_000
			),
		};

		sendTransactions(tx, {
			processingMessage: "Drawing winner",
			errorMessage: "An error has occured during the draw",
			successMessage: "Draw successful",
		}).then(({ sessionId }) => {
			setTransactionId(sessionId);
		});
	};

	return (
		<>
			<div className="text-center">
				{prizes.map((prize, i) => (
					<h2 className="fw-bold mb-4">
						{i + 1} winner prize:&nbsp;
						<span className="text-primary">
							{prize.nonce !== 0 &&
							prize.amountAsBigInteger.eq(1) ? (
								prize.tokenIdentifier + " #" + prize.nonce
							) : (
								<FormatAmount
									value={prize}
									token={prize.tokenIdentifier}
									decimals={
										tokens.find(
											(token) =>
												token.identifier ===
												prize.tokenIdentifier
										)?.decimals ?? 0
									}
									showLastNonZeroDecimal={true}
									digits={0}
								/>
							)}
						</span>
					</h2>
				))}
				<h2 className="fw-bold mb-4">
					Number of partecipants:&nbsp;
					<span className="text-primary">
						{participantsToSend.length}
					</span>
				</h2>
				<h2 className="fw-bold">
					Contract to use:&nbsp;
					<span className="text-primary">
						xBulk: {contractToUse.name}
					</span>
				</h2>
			</div>

			<div className="text-center my-5">
				<button
					className="btn btn-secondary py-2 px-4 mx-2"
					onClick={prevStep}
				>
					Back
				</button>
				<button
					className="btn btn-primary py-2 px-4 mx-2"
					onClick={sendDrawTransaction}
				>
					Send
				</button>
			</div>

			<table className="table table-mvx">
				<thead>
					<tr>
						<th scope="col" colSpan={2}>
							List of participants
						</th>
					</tr>
					<tr>
						<th scope="col">#</th>
						<th scope="col">ADDRESS</th>
					</tr>
				</thead>

				<tbody>
					{participantsToSend.map((participant, i) => (
						<tr key={i}>
							<th scope="row">{i + 1}.</th>
							<td>{participant.bech32()}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default Step2;
