import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";
import { getContracts } from "config";
import { Step1, Step2, Step3 } from "components/pages/lottery";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import BannedPage from "components/BannedPage";
import bannedAddresses from "bannedAddresses";

const Lottery = () => {
	const steps = [
		"Prepare your Lottery",
		"Check the Lottery Summary",
		"Done!",
	];
	const [step, setStep] = useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transactionId, setTransactionId] = useState(null);
	const transactionStatus = useTrackTransactionStatus({ transactionId });

	const account = useGetAccountInfo();
	const [contracts, setContracts] = useState([]);
	const [tokens, setTokens] = useState([]);

	const [userInput, setUserInput] = useState({
		prizes: [],
		list: "",
		contract: null,
	});
	const [participantsToSend, setParticipantsToSend] = useState([]);

	useEffect(() => {
		(async () => {
			apiNetworkProvider
				.getAccountAllTokens(account.address)
				.then((tokens) => {
					setTokens((prev) => [...tokens, ...prev]);
					setUserInput((prev) => ({
						...prev,
						prizes: [
							{
								token: tokens[0].identifier,
								amount: "",
							},
						],
					}));
				});

			apiNetworkProvider
				.getAccountAllNfts(account.address)
				.then((nfts) => {
					setTokens((prev) => [...prev, ...nfts]);
				});
		})();
	}, []);

	useEffect(() => {
		getContracts(account.address, apiAddress).then((contracts) => {
			setContracts(contracts);
			setUserInput((prev) => ({
				...prev,
				contract: (contracts[0] as any).address,
			}));
		});
	}, [account.address, apiAddress]);

	useEffect(() => {
		if (transactionStatus.isPending) {
			nextStep();
		}
	}, [transactionStatus.isPending]);

	if (bannedAddresses.includes(account.address)) {
		return <BannedPage />;
	}

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						Make&nbsp;
						<span className="text-primary">your lotteries</span> in
						the most transparent and easy way!
					</>
				}
				subtitle="Insert the list of the participants and the prizes you want to send,
				the smart contarct will extract the list of winners and send them the prizes automatically."
			/>
			<PageBody>
				<BodyHeader title={`Step ${step}`} subtitle={steps[step - 1]} />

				{step === 1 && (
					<Step1
						userInput={userInput}
						setUserInput={setUserInput}
						setParticipantsToSend={setParticipantsToSend}
						tokens={tokens}
						contracts={contracts}
						nextStep={nextStep}
					/>
				)}
				{step === 2 && (
					<Step2
						userInput={userInput}
						participantsToSend={participantsToSend}
						tokens={tokens}
						contracts={contracts}
						prevStep={prevStep}
						nextStep={nextStep}
						setTransactionId={setTransactionId}
					/>
				)}
				{step === 3 && (
					<Step3
						prevStep={prevStep}
						participantsToSend={participantsToSend}
						transactionStatus={transactionStatus}
					/>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default Lottery;
