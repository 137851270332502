import React from "react";

const PageBody = ({ children }) => {
	return (
		<div className="card card-glowing">
			<div className="card-body p-3 p-md-5">{children}</div>
		</div>
	);
};

export default PageBody;
