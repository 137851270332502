import { dAppName } from "config";
import withPageTitle from "./components/PageTitle";
import Home from "./pages/Home";
import UnlockPage from "./pages/UnlockPage";
import ContractCreator from "pages/ContractCreator";

import BulkTransaction from "pages/BulkTransaction";
import NftDistribution from "pages/NftDistribution";
import Lottery from "pages/Lottery";
import NftMultiSend from "pages/NftMultiSend";
import LotteryResult from "pages/LotteryResult";

export const routeNames = {
	home: "/",
	bulkTransaction: "/bulkTransaction",
	nftDistribution: "/nftDistribution",
	lottery: "/lottery",
	nftMultiSend: "/nftMultiSend",
	contractCreator: "/contractCreator",
	lotteryResult: "/lotteryResult/:txHash",
	unlock: "/unlock",
	ledger: "/ledger",
	walletconnect: "/walletconnect",
};

const routes: Array<any> = [
	{
		path: routeNames.home,
		title: "Home",
		component: Home,
	},
	{
		path: routeNames.bulkTransaction,
		title: "Bulk Transaction",
		component: BulkTransaction,
		authenticatedRoute: true,
	},
	{
		path: routeNames.nftDistribution,
		title: "NFT Distribution",
		component: NftDistribution,
		authenticatedRoute: true,
	},
	{
		path: routeNames.lottery,
		title: "Lottery",
		component: Lottery,
		authenticatedRoute: true,
	},
	{
		path: routeNames.nftMultiSend,
		title: "NFT Multi Send",
		component: NftMultiSend,
		authenticatedRoute: true,
	},
	{
		path: routeNames.contractCreator,
		title: "Contract Creator",
		component: ContractCreator,
		authenticatedRoute: true,
	},
	{
		path: routeNames.lotteryResult,
		title: "Lottery Result",
		component: LotteryResult,
	},
	{
		path: routeNames.unlock,
		title: "Unlock",
		component: UnlockPage,
	},
];

const mappedRoutes = routes.map((route) => {
	const title = route.title ? `${route.title} • ${dAppName}` : `${dAppName}`;

	const requiresAuth = Boolean(route.authenticatedRoute);
	const wrappedComponent = withPageTitle(title, route.component);

	return {
		path: route.path,
		component: wrappedComponent,
		authenticatedRoute: requiresAuth,
	};
});

export default mappedRoutes;
