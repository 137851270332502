import React, { useState, useEffect } from "react";
import PageTemplate from "components/PageTemplate";
import PageHeader from "components/PageHeader";
import PageBody from "components/PageBody";
import BodyHeader from "components/BodyHeader";
import { Step1, Step2, Step3 } from "components/pages/contractCreator";
import { useGetNetworkConfig } from "@multiversx/sdk-dapp/hooks/useGetNetworkConfig";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import { useTrackTransactionStatus } from "@multiversx/sdk-dapp/hooks";
import { MyApiNetworkProvider } from "MyApiNetworkProvider";
import BannedPage from "components/BannedPage";
import bannedAddresses from "bannedAddresses";

const ContractCreator = () => {
	const steps = ["Choose a name for your contract", "Check details", "Done!"];
	const [step, setStep] = useState(1);
	const nextStep = () => setStep(step + 1);
	const prevStep = () => setStep(step - 1);

	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const [transactionId, setTransactionId] = useState(null);
	const transactionStatus = useTrackTransactionStatus({ transactionId });

	const account = useGetAccountInfo();

	const [userInput, setUserInput] = useState("");

	useEffect(() => {
		if (transactionStatus.isPending) {
			nextStep();
		}
	}, [transactionStatus.isPending]);

	if (bannedAddresses.includes(account.address)) {
		return <BannedPage />;
	}

	return (
		<PageTemplate>
			<PageHeader
				title={
					<>
						Create your&nbsp;
						<span className="text-primary">private smart contract</span> for
						xBulk
					</>
				}
				subtitle="Create your own private smart contract for xBulk. 
				Only you will be able to use it, and your users will know that transactions are coming from you."
			/>
			<PageBody>
				<BodyHeader title={`Step ${step}`} subtitle={steps[step - 1]} />

				{step === 1 && (
					<Step1
						userInput={userInput}
						setUserInput={setUserInput}
						nextStep={nextStep}
					/>
				)}

				{step === 2 && (
					<Step2
						userInput={userInput}
						prevStep={prevStep}
						nextStep={nextStep}
						setTransactionId={setTransactionId}
					/>
				)}

				{step === 3 && (
					<Step3
						userInput={userInput}
						prevStep={prevStep}
						transactionStatus={transactionStatus}
					/>
				)}
			</PageBody>
		</PageTemplate>
	);
};

export default ContractCreator;
