import React from "react";
import { maxTxPerCall } from "utils/constants";
import { sendTransactions } from "utils/functions";
import { getContracts } from "config";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import {
	Address,
	TokenPayment,
	TransferTransactionsFactory,
	GasEstimator,
} from "@multiversx/sdk-core";
import { string2hex } from "utils/functions";

const Step2 = ({
	userInput,
	nftsToSend,
	contracts,
	prevStep,
	nextStep,
	setTransactionId,
}) => {
	const nCalls = Math.ceil(nftsToSend.length / maxTxPerCall);
	let calls = [];

	const account = useGetAccountInfo();
	const contractToUse = contracts.find(
		(contract) => contract.address === userInput.contract
	);

	const sendNftDistribution = async () => {
		const nTxPerCall = Math.ceil(nftsToSend.length / nCalls);

		for (let i = 0; i < nftsToSend.length; i += nTxPerCall) {
			const callTxs = nftsToSend.slice(i, i + nTxPerCall);
			let payments = [];
			let addresses = [];

			// eslint-disable-next-line no-loop-func
			callTxs.forEach((tx) => {
				const identifierComponents = tx.identifier.split("-");
				const tokenIdentifier =
					identifierComponents[0] + "-" + identifierComponents[1];
				const nonce = parseInt(identifierComponents[2], 16);
				const txPayment = TokenPayment.nonFungible(
					tokenIdentifier,
					nonce
				);

				payments.push(txPayment);
				addresses.push(tx.address);
			});

			let payload = new TransferTransactionsFactory(new GasEstimator())
				.createMultiESDTNFTTransfer({
					tokenTransfers: payments,
					destination: new Address(contractToUse.address),
					sender: new Address(account.address),
					chainID: "1",
				})
				.getData()
				.toString();

			payload += "@";
			payload += string2hex("nftDistribution");
			payload += "@";
			payload += addresses.map((a) => a.hex()).join("@");

			calls.push({
				value: 0,
				data: payload,
				receiver: account.address,
				gasLimit: Math.min(
					1_600_000 + 1_500_000 * callTxs.length,
					600_000_000
				),
			});
		}

		sendTransactions(calls, {
			processingMessage: "Processing NFT distribution",
			errorMessage: "An error has occured during NFT distribution",
			successMessage: "NFT distribution successful",
		}).then(({ sessionId }) => {
			setTransactionId(sessionId);
		});
	};

	return (
		<>
			<div className="text-center">
				<h2 className="fw-bold mb-4">
					Number of transactions:&nbsp;
					<span className="text-primary">{nftsToSend.length}</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Number of contract calls to sign:&nbsp;
					<span className="text-primary">{nCalls}</span>
				</h2>
				<h2 className="fw-bold">
					Contract to use:&nbsp;
					<span className="text-primary">
						xBulk: {contractToUse.name}
					</span>
				</h2>
			</div>

			<div className="text-center my-5">
				<button
					className="btn btn-secondary py-2 px-4 mx-2"
					onClick={prevStep}
				>
					Back
				</button>
				<button
					className="btn btn-primary py-2 px-4 mx-2"
					onClick={sendNftDistribution}
				>
					Send
				</button>
			</div>

			<table className="table table-mvx">
				<thead>
					<tr>
						<th scope="col" colSpan={3}>
							List of NFTs to distribute
						</th>
					</tr>
					<tr>
						<th scope="col">#</th>
						<th scope="col">ADDRESS</th>
						<th scope="col">NFT</th>
					</tr>
				</thead>

				<tbody>
					{nftsToSend.map((nft, i) => (
						<tr key={i}>
							<th scope="row">{i + 1}.</th>
							<td>{nft.address.bech32()}</td>
							<td>{nft.identifier}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default Step2;
