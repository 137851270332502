import React from "react";
import Globe from "assets/img/globe.png";

export const InitialBackground = () => {
	return (
		<div className="background">
			<img src={Globe} alt="" className="globe globe-1" />
			<img src={Globe} alt="" className="globe globe-2" />
		</div>
	);
};
