import React from "react";
import { maxTxPerCall } from "utils/constants";
import { sendTransactions } from "utils/functions";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks/account/useGetAccountInfo";
import {
	TokenPayment,
	Address,
	TransferTransactionsFactory,
	GasEstimator,
} from "@multiversx/sdk-core";
import { shortenAddress } from "utils/functions";

const Step2 = ({ userInput, prevStep, nextStep, nfts, setTransactionId }) => {
	const nftsToSend = nfts.filter((nft) => nft.toSend);

	const nCalls = Math.ceil(nftsToSend.length / maxTxPerCall);

	const account = useGetAccountInfo();

	const sendNfts = async () => {
		let calls = [];
		const nTxPerCall = Math.ceil(nftsToSend.length / nCalls);

		for (let i = 0; i < nftsToSend.length; i += nTxPerCall) {
			const callNfts = nftsToSend.slice(i, i + nTxPerCall);
			let payments = [];

			// eslint-disable-next-line no-loop-func
			callNfts.forEach((nft) => {
				const identifierComponents = nft.identifier.split("-");
				const tokenIdentifier =
					identifierComponents[0] + "-" + identifierComponents[1];
				const nonce = parseInt(identifierComponents[2], 16);
				const txPayment = TokenPayment.nonFungible(
					tokenIdentifier,
					nonce
				);

				payments.push(txPayment);
			});

			let payload = new TransferTransactionsFactory(new GasEstimator())
				.createMultiESDTNFTTransfer({
					tokenTransfers: payments,
					destination: new Address(userInput.receiverObj),
					sender: new Address(account.address),
					chainID: "1",
				})
				.getData()
				.toString();

			calls.push({
				value: 0,
				data: payload,
				receiver: account.address,
				gasLimit: 1_100_000 * payments.length,
			});
		}

		sendTransactions(calls, {
			processingMessage: "Sending NFTs",
			errorMessage: "An error has occured while sending NFTs",
			successMessage: "NFTs sent successfully",
		}).then(({ sessionId }) => {
			setTransactionId(sessionId);
		});
	};

	return (
		<>
			<div className="text-center">
				<h2 className="fw-bold mb-4">
					Number of NFTs:&nbsp;
					<span className="text-primary">{nftsToSend.length}</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Number of calls to sign:&nbsp;
					<span className="text-primary">{nCalls}</span>
				</h2>
				<h2 className="fw-bold mb-4">
					Receiver:&nbsp;
					<span className="text-primary">
						{shortenAddress(
							userInput?.receiverObj?.bech32?.() ?? ""
						)}
					</span>
				</h2>
			</div>

			<div className="text-center my-5">
				<button
					className="btn btn-secondary py-2 px-4 mx-2"
					onClick={prevStep}
				>
					Back
				</button>
				<button
					className="btn btn-primary py-2 px-4 mx-2"
					onClick={sendNfts}
				>
					Send
				</button>
			</div>

			<table className="table table-mvx">
				<thead>
					<tr>
						<th scope="col" colSpan={3}>
							List of NFTs to send
						</th>
					</tr>
					<tr>
						<th scope="col">#</th>
						<th scope="col">NFT</th>
					</tr>
				</thead>

				<tbody>
					{nftsToSend.map((nft, i) => (
						<tr key={i}>
							<th scope="row">{i + 1}.</th>
							<td>{nft.identifier}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default Step2;
