import React from "react";
import { useGetLoginInfo } from "@multiversx/sdk-dapp/hooks";
import {
	ExtensionLoginButton,
	WebWalletLoginButton,
	LedgerLoginButton,
	WalletConnectLoginButton,
	XaliasLoginButton,
	MetamaskProxyButton,
} from "@multiversx/sdk-dapp/UI";
import { routeNames } from "routes";
import { useLocation } from "react-router-dom";
import { walletConnectV2ProjectId } from "config";
import { InitialBackground } from "components/InitialBackground";

export const UnlockRoute: () => JSX.Element = () => {
	const { isLoggedIn } = useGetLoginInfo();

	React.useEffect(() => {
		if (isLoggedIn) {
			window.location.href = routeNames.home;
		}
	}, [isLoggedIn]);

	const { state } = useLocation();

	return (
		<>
			<InitialBackground />
			<div className="home d-flex flex-fill align-items-center">
				<div className="m-auto">
					<div className="card card-glowing rounded-4 my-4">
						<div className="card-body py-5 px-3 mx-lg-4">
							<h1 className="mb-2 text-primary fw-bold">
								Welcome
							</h1>
							<p className="mb-8 text-light">
								Choose a login method
							</p>

							<ExtensionLoginButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								callbackRoute={routeNames.home}
							/>
							<LedgerLoginButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								callbackRoute={routeNames.home}
							/>
							<WalletConnectLoginButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								callbackRoute={routeNames.home}
								{...(walletConnectV2ProjectId
									? {
											isWalletConnectV2: true,
									  }
									: {})}
							/>
							<MetamaskProxyButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								loginButtonText="Metamask"
								callbackRoute={routeNames.home}
							/>

							<hr />

							<p className="my-4 text-light">
								Due to technical limitations, Web Wallet &
								xAlias login only supports up to 80
								transactions.
								<br />
								We suggest using the Extension login for a
								better experience.
							</p>
							<WebWalletLoginButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								callbackRoute={routeNames.home}
								nativeAuth={true}
							/>
							<XaliasLoginButton
								buttonClassName="btn btn-primary py-2 px-5 mx-2"
								callbackRoute={routeNames.home}
								nativeAuth={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default UnlockRoute;
